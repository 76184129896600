import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "sidebarMenu",
    "sidebarPlaces",
    "body",
    "cartMask",
    "sidebarMask",
    "placesMask",
    "accountDropdown",
    "cart",
    "hero",
    "header",
    "card",
    "cardImage"
  ];

  toggleHero(event) {
    let headerBottom =
      this.headerTarget.offsetTop + this.headerTarget.offsetHeight;

    if (event.currentTarget.scrollY > headerBottom) {
      this.headerTarget.classList.add("sticky");
      this.heroTarget.classList.add("h-0", "hidden");
      this.heroTarget.classList.remove("h-full", "lg:h-[360px]");
      this.makeCardsHorizontal();
    } else if (event.currentTarget.scrollY == 0) {
      this.headerTarget.classList.remove("sticky");
      this.heroTarget.classList.remove("h-0", "hidden");
      this.heroTarget.classList.add("h-full", "lg:h-[360px]");
      this.makeCardsVertical();
    }
  }

  toggleCardsSize(event) {
    if (event.currentTarget.scrollY > this.headerTarget.offsetHeight) {
      this.headerTarget.classList.add("sticky");
      this.makeCardsHorizontal();
    } else {
      this.headerTarget.classList.remove("sticky");
      this.makeCardsVertical();
    }
  }

  disconnect() {
    this.closeSidebarMenu();
    this.closeSidebarPlaces();
  }

  openSidebarMenu() {
    this.sidebarMenuTarget.classList.remove("left-[-100%]");
    this.sidebarMenuTarget.classList.add("left-0");
    document.body.classList.add("max-lg:h-full");
    document.body.classList.add("max-lg:overflow-hidden");
    this.sidebarMaskTarget.classList.remove("hidden");
  }

  closeSidebarMenu() {
    this.sidebarMenuTarget.classList.add("left-[-100%]");
    this.sidebarMenuTarget.classList.remove("left-0");
    this.sidebarMaskTarget.classList.add("hidden");
    document.body.classList.remove("max-lg:h-full");
    document.body.classList.remove("max-lg:overflow-hidden");
  }

  openSidebarPlaces() {
    this.sidebarPlacesTarget.classList.remove("right-[-100%]");
    this.sidebarPlacesTarget.classList.add("right-0");
    document.body.classList.add("h-full");
    document.body.classList.add("overflow-hidden");
    this.placesMaskTarget.classList.remove("hidden");
  }

  closeSidebarPlaces() {
    this.sidebarPlacesTarget.classList.add("right-[-100%]");
    this.sidebarPlacesTarget.classList.remove("right-0");
    document.body.classList.remove("h-full");
    document.body.classList.remove("overflow-hidden");
    this.placesMaskTarget.classList.add("hidden");
  }

  makeCardsHorizontal() {
    this.cardTargets.forEach((card) => {
      card.classList.add("lg:w-[152px]", "lg:flex-row");
      card.classList.remove("lg:w-[104px]", "lg:flex-col");
    });
    this.cardImageTargets.forEach((cardImage) => {
      cardImage.classList.add("h-12");
      cardImage.classList.remove("h-14");
    });
  }

  makeCardsVertical() {
    this.cardTargets.forEach((card) => {
      card.classList.remove("lg:w-[152px]", "lg:flex-row");
      card.classList.add("lg:w-[104px]", "lg:flex-col");
    });
    this.cardImageTargets.forEach((cardImage) => {
      cardImage.classList.remove("h-12");
      cardImage.classList.add("h-14");
    });
  }
}
