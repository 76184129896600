import { Controller as BaseController } from "@hotwired/stimulus"
import { turboRequest } from "../../../../../../javascript/customer_web/turbo_request";

export class Controller extends BaseController {
  static get targets() {
    return [ "form", "input", "clearInputIcon" ]
  }

  connect() {
    if (this.inputTarget) {
      const url = new URL(window.location.href);
      if (url.searchParams.get('q')) {
        this.inputTarget.value = url.searchParams.get('q');
        this.clearInputIconTarget.classList.remove('hidden');
      }
    }
  }

  autocomplete(event) {
    clearTimeout(this.timeout);

    var request = turboRequest(event.target.getAttribute('data-url'), 'POST', { q: event.target.value }, { credentials: 'same-origin' });
    
    this.timeout = setTimeout(() => {
      fetch(request)
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      });
    }, 500)
    if (this.inputTarget.value === '') {
      this.clearInputIconTarget.classList.add('hidden');
    } else {
      this.clearInputIconTarget.classList.remove('hidden');
    }
  }

  clearInput() {
    this.inputTarget.value = '';
    const e = new Event('change');
    this.inputTarget.dispatchEvent(e);
    this.autocomplete(e)
    this.inputTarget.focus();
    this.clearInputIconTarget.classList.add('hidden');
  }
}
