import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
let debounce = require("lodash/debounce");

export default class extends Controller {
  static get targets() {
    return ["analytics", "loader", "quantity", "quantityMask"];
  }

  static get values() {
    return {
      url: String,
      variantId: Number,
      quantity: Number,
    };
  }

  initialize() {
    this.updateCart = debounce(this.updateCart, 400).bind(this);
  }

  increaseQuantity() {
    this.quantityValue++;
    this.updateQuantityMask();
    this.updateCart();
  }

  decreaseQuantity() {
    if (this.quantityValue == 0) return;

    this.quantityValue--;
    this.updateQuantityMask();
    this.updateCart();
  }

  updateCart() {
    this.dispatch("updateCart", {
      detail: {
        content: {
          variant_id: this.variantIdValue,
          quantity: this.quantityValue,
        }
      }
    })
    this.toggleLoader();
  }

  showSlider(e) {
    e.preventDefault();
    this.toggleLoader();

    get(this.url, { responseKind: "turbo-stream" })
      .then(() => this.toggleLoader());
  }

  get url() {
    let result = atob(this.urlValue);

    if (window.location.pathname.search(/^\/epiceries\/*/) >= 0) {
      result += "?without_shop=true";
    }

    return result;
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }

  updateQuantityMask() {
    this.quantityTargets.forEach(element => element.innerHTML = this.quantityValue);
    if (this.quantityValue > 0) {
      this.quantityMaskTarget.classList.remove('hidden');
    } else {
      this.quantityMaskTarget.classList.add('hidden');
    }
  }

  resetCart() {
    this.quantityMaskTarget.classList.add('hidden');
    this.quantityValue = 0;
  }
}
