import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
let debounce = require("lodash/debounce");

export default class extends Controller {
  static get targets() {
    return ["loader"];
  }

  static get values() {
    return {
      url: { type: String, default: '/cw/cart' },
    };
  }

  initialize() {
    this.callCart = debounce(this.callCart, 1000).bind(this);
    this.waitingList = [];
    this.working = false;
  }

  update({ detail: { content } }) {
    this.addToWaitingList(content);
    this.callCart();
  }

  callCart() {
    if (this.waitingList.length == 0 || this.working) { return }

    this.working = true;
    this.toggleLoader();
    let params = this.waitingList;
    this.waitingList = [];

    post(this.url, { body: JSON.stringify({ variants: params }), responseKind: "turbo-stream" })
      .then((response) => { if (this.isAddressInvalid(response)) { this.resetProductCards(params) } })
      .then(() => this.working = false)
      .then(() => this.toggleLoader())
      .then(() => this.dismissAllProductLoader(params))
      .then(() => this.callCart());
  }

  addToWaitingList(content) {
    const find = this.waitingList.find((element) => element.variant_id == content.variant_id)
    if (find) {
      this.waitingList = this.waitingList.map((element) => {
        if (element.variant_id == content.variant_id) {
          return content;
        } else {
          return element;
        }
      })
    } else {
      this.waitingList.push(content);
    }
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }

  dismissAllProductLoader(params) {
    params.forEach((param) => document.querySelectorAll(`[id='product-card-${param.variant_id}'] .loader`).forEach((element) => element.classList.add('hidden')))
  }

  get url() {
    if (window.location.pathname.search(/^\/epiceries\/*/) >= 0) {
      return this.urlValue + "?without_shop=true";
    }

    return this.urlValue;
  }

  isAddressInvalid(response) {
    return response.responseText.then(text => { text.includes("Entrez votre adresse pour découvrir les produits disponibles") })
  }

  resetProductCards(params) {
    params.forEach((param) => {
      const elements = document.getElementsByClassName(`product-card-${param.variant_id}-content`);
      Array.from(elements).forEach((element) => {
        const productCardController = this.application.getControllerForElementAndIdentifier(element, 'product-card')
        productCardController.resetCart()
      });
    })
  }
}
