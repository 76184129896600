import { Controller as BaseController } from "@hotwired/stimulus";
import { turboRequest } from "../../../../../javascript/customer_web/turbo_request";

let debounce = require("lodash/debounce");

export class Controller extends BaseController {
  static get targets() {
    return [
      "container",
      "history",
      "suggestion",
      "places",
      "input",
      "loader",
      "icon",
      "submitButton",
      "form",
      "delete",
    ];
  }

  static get values() {
    return { reloadOnRemove: Boolean };
  }

  initialize() {
    this.searchPlace = debounce(this.searchPlace, 500).bind(this);
  }

  connect() {
    this.updateInputs();
    if (this.inputTarget.value) {
      this.deleteTarget.classList.remove("hidden");
      this.iconTarget.classList.remove("text-gray-900");
      this.iconTarget.classList.add("text-emerald-700");
    }
  }

  async submit(event = null) {
    if (event) {
      event.preventDefault();
    }

    this.toggleLoader();

    await fetch(this.placeRequest).then(async (response) => {
      const res = await response.json();

      if (res.error && response.status === 404) {
        this.toggleLoader();
        this.modalAddressErrorController.toggleModal("notFound");
        this.modalController.closeModal();
      } else if (res.error && res.errorType == "impreciseAddress") {
        await fetch(this.modalImpreciseRequest)
          .then((response) => response.text())
          .then((html) => Turbo.renderStreamMessage(html))
          .then(this.updateInputs());
      } else {
        const dataAnalytics =  this.inputTarget.dataset.analytics;
        const dataAnalyticsJson = JSON.parse(dataAnalytics)
        Object.entries(dataAnalyticsJson).forEach(([key, value]) => {
          if(key === "label"){     
            dataAnalyticsJson[key] = this.inputTarget.value;
           }
        });
        if (dataAnalytics !== undefined) {
          dataLayer.push(JSON.parse(JSON.stringify(dataAnalyticsJson)));
        }

        if (this.eventToTrigger && this.eventToTrigger.href) {
          await fetch(this.eventToTriggerRequest).then(async (response) => {
            location.reload();
          });
        } else {
          location.reload();
        }
      }
    });
  }

  async searchPlace(event) {
    if (event.code == "Enter" || event.code == "Backspace") {
      return;
    }

    const geoRequest = new Request(
      encodeURI(`/cw/addresses?q=${event.target.value}`)
    );

    await fetch(geoRequest).then(async (response) => {
      const res = await response.json();

      if (res.hasAddresses || res.hasResults) {
        this.placesTargets.forEach((place) => place.remove());

        this.containerTarget.classList.remove("hidden");

        if (res.hasAddresses) {
          this.historyTarget.classList.remove("hidden");
          res.addresses.forEach((element) => {
            this.historyTarget.after(this.createPlaceDiv(element));
          });
        }
        if (res.hasResults) {
          this.suggestionTarget.classList.remove("hidden");
          res.results.forEach((element) => {
            this.suggestionTarget.after(this.createPlaceDiv(element));
          });
        }
      } else if (!res.hasAddresses && !res.hasResults) {
        this.containerTarget.classList.add("hidden");
      }
    });
  }

  async setAddress(event) {
    this.inputTarget.value = event.target.innerHTML;
    this.containerTarget.classList.add("hidden");

    await this.submit();
  }

  async removeAddress() {
    this.toggleLoader();
    const removeAddressRequest = new Request("/cw/addresses", {
      method: "DELETE",
    });

    await fetch(removeAddressRequest).then(() => {
      if (this.reloadOnRemoveValue) {
        location.reload();
      } else {
        this.inputTarget.value = "";
        this.updateInputs();
        this.toggleLoader();
      }
    });
  }

  toggleLoader() {
    this.iconTarget.classList.toggle("hidden");
    this.loaderTarget.classList.toggle("hidden");
  }

  createPlaceDiv(html) {
    const place = document.createElement("div");
    place.innerHTML = html;
    place.classList.add(
      "cursor-pointer",
      "py-1",
      "px-2.5",
      "last:hover:rounded-b-md",
      "overflow-hidden",
      "text-[16px]",
      "leading-[18px]",
      "text-black",
      "hover:bg-gray-100"
    );
    place.setAttribute(`data-${this.identifier}-target`, "places");
    place.setAttribute("data-action", `click->${this.identifier}#setAddress`);

    return place;
  }

  get placeRequest() {
    return new Request("/cw/addresses", {
      body: JSON.stringify({ address: this.inputTarget.value }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
  }

  get modalAddressErrorController() {
    const elem = document.getElementById(
      "customer_web--atoms--modal_address_error"
    );

    return this.application.getControllerForElementAndIdentifier(
      elem,
      "customer_web--atoms--modal_address_error"
    );
  }

  get modalController() {
    const elem = document.querySelector("[data-controller='customer_web--atoms--modal']");

    return this.application.getControllerForElementAndIdentifier(
      elem,
      "customer_web--atoms--modal"
    );
  }

  enableSubmit() {
    if (this.hasSubmitButtonTarget != true) {
      return;
    }
    const initialInputValue = this.inputTarget.getAttribute("value");
    const currentInputValue = this.inputTarget.value;
    this.submitButtonTarget.disabled = false;

    if (currentInputValue === initialInputValue) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }

  get eventToTrigger() {
    return JSON.parse(this.formTarget.dataset.eventToTrigger);
  }

  get eventToTriggerRequest() {
    return turboRequest(
      this.eventToTrigger.href,
      this.eventToTrigger.method,
      this.eventToTrigger.payload
    );
  }

  get modalImpreciseRequest() {
    return turboRequest(
      `/cw/addresses/error?address=${this.inputTarget.value}`,
      "GET"
    );
  }

  updateInputs() {
    document
      .querySelectorAll(
        'input[data-customer_web--molecules--address_search-target="input"]'
      )
      .forEach((element) => {
        element.value = this.inputTarget.value;
      });
    document
      .querySelectorAll(
        'i[data-customer_web--molecules--address_search-target="delete"]'
      )
      .forEach((element) => {
        if (this.inputTarget.value) {
          element.classList.remove("hidden");
        } else {
          element.classList.add("hidden");
        }
      });
  }

  clickOutside(e) {
    if(this.containerTarget) {
      if(this.element === e.target || this.element.contains(e.target)) return;
      this.containerTarget.classList.add("hidden");
    }
  }
}
