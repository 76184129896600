import { Controller as BaseController } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export class Controller extends BaseController {
  static get targets() {
    return [ "addressButton" ]
  }

  openAddressModal(event) {
    event.preventDefault();
    const targetUrl = this.addressButtonTarget.dataset.addressModalUrl;

    if (targetUrl !== undefined) {
      get(targetUrl, {responseKind: "turbo-stream"})
    }
  }
}
