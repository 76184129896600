import { Controller as BaseController } from "@hotwired/stimulus";
import { turboRequest } from "../../../../../../javascript/customer_web/turbo_request";

export class Controller extends BaseController {
  static get targets() {
    return ["loader", "modal"];
  }

  static get values() {
    return {
      lineItemIds: String,
    };
  }

  deleteShipment() {
    this.loaderTarget.classList.toggle("hidden");

    fetch(
      turboRequest(this.url, "DELETE", {
        line_item_ids: this.lineItemIdsValue,
      })
    )
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));

    this.toggleModal();
  }

  toggleModal() {
    this.modalTarget.classList.toggle("right-[-100%]");
    this.modalTarget.classList.toggle("right-0");
  }

  get url() {
    let result = "/cw/cart/destroy_batch";

    if (window.location.pathname.search(/^\/epiceries\/*/) >= 0) {
      result += "?without_shop=true";
    }

    return result;
  }
}
