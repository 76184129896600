import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static get targets() {
    return ["modal", "background"]
  }

  connect() {
    if (!this.shouldDisplayModal()) { return; }
    
    setTimeout(() => {
      localStorage.setItem("display_closing_epicery_popup_at", new Date().getTime());
      setTimeout(() => {
        this.modalTarget.classList.remove("max-lg:translate-y-full", "lg:-translate-x-full");
        this.modalTarget.classList.add("lg:left-6", "max-lg:top-[10%]");
        this.backgroundTarget.classList.add("max-lg:bg-black", "max-lg:opacity-50", "max-lg:h-full");
      }, 250);

      document.body.classList.add("max-lg:h-full");
      document.body.classList.add("max-lg:overflow-hidden");
    }, 3000)
  }

  closeModal() {
    setTimeout(() => {
      this.modalTarget.classList.remove("lg:left-6", "max-lg:top-[10%]");
      this.modalTarget.classList.add("max-lg:translate-y-full", "lg:-translate-x-full");
      this.backgroundTarget.classList.remove("max-lg:bg-black", "max-lg:opacity-50", "max-lg:h-full");
    }, 250);
    document.body.classList.remove("max-lg:h-full");
    document.body.classList.remove("max-lg:overflow-hidden");
  }

  shouldDisplayModal() {
    const displayClosingEpiceryPopup = localStorage.getItem("display_closing_epicery_popup_at");
    return displayClosingEpiceryPopup < new Date().getTime() - 86400000;
  }
}
